import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkboxAll", "checkbox"]
  static values = { disableIndeterminate: Boolean }

  initialize() {
    this.toggle = this.toggle.bind(this)
    this.refresh = this.refresh.bind(this)
  }

  connect() {
    this.refresh()
  }

  checkboxAllTargetConnected(checkbox) {
    checkbox.addEventListener("change", this.toggle)
    this.refresh()
  }

  checkboxTargetConnected(checkbox) {
    checkbox.addEventListener("change", this.refresh)
    this.refresh()
  }

  checkboxAllTargetDisconnected(checkbox) {
    checkbox.removeEventListener("change", this.toggle)
    this.refresh()
  }

  checkboxTargetDisconnected(checkbox) {
    checkbox.removeEventListener("change", this.refresh)
    this.refresh()
  }

  toggle(e) {
    e.preventDefault()
    const allChecked = e.target.checked

    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = allChecked
      this.triggerInputEvent(checkbox)
    })
  }

  refresh() {
    const total = this.checkboxTargets.length
    const checkedCount = this.checked.length

    if (this.disableIndeterminateValue) {
      this.checkboxAllTarget.checked = (checkedCount === total)
    } else {
      this.checkboxAllTarget.checked = (checkedCount > 0)
      this.checkboxAllTarget.indeterminate = (checkedCount > 0 && checkedCount < total)
    }
  }

  triggerInputEvent(checkbox) {
    const event = new Event("input", { bubbles: false, cancelable: true })
    checkbox.dispatchEvent(event)
  }

  get checked() {
    return this.checkboxTargets.filter((c) => c.checked)
  }

  get unchecked() {
    return this.checkboxTargets.filter((c) => !c.checked)
  }
}
